import React from 'react'
import Header from "./header.js"
import Footer from "./footer.js"

export default ({ children }) => (
  <div
    className="max-w-xl lg:max-w-4xl xl:max-w-6xl clearfix text-pjf-gray font-sans p-4 md:p-0 mx-auto my-4 md:my-12 leading-normal text-base antialiased"
    style={{ lineHeight: `1.5`}}
  >
  <Header />
    <div className="flex flex-col-reverse lg:flex-row">
      {children}
    </div>
  <Footer />
  </div>
)
