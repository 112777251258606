import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

const ListLink = props => (
  <li className="text-sm inline-block mr-4 last:mr-0">
    <Link to={props.to}>{props.children}</Link>
  </li>
)
export default ({ data }) => (

  <header className="p-4 text-center lg:text-left">

    <Link className="inline-block" to="/">
    <StaticQuery
  query={graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `}
  render={data => <Img fixed={data.logo.childImageSharp.fixed} />}
/>
    
    </Link>
    
    <ul className="lg:float-right ml-0" style={{ lineHeight: `55px`}}>
      <ListLink to="/">Home</ListLink>
      <ListLink to="/scholarships/">Scholarships</ListLink>
      <ListLink to="/history/">History</ListLink>
    </ul>
  </header>
)
